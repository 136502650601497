
export default function onClick(id?: string) {
  if (!id) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  } else {
    const nav = document.getElementById('nav');
    const navHeight = nav ? nav.getBoundingClientRect().height : 50;

    const section = document.getElementById(id);
    if (section) {
      const offsetTop = section.getBoundingClientRect().top;
      const totalOffset = offsetTop - navHeight + window.scrollY;
      if (!totalOffset) {
        return;
      }
      window.scroll({ top: totalOffset, left: 0, behavior: 'smooth' });
    }
  }
}
