import React, { useState, Children, useCallback } from "react";

export default function Hoverable({ hoverStyle, children, ...props }: any) {
  const [hover, setHover] = useState(false);

  const child = Children.only(children);

  const onHoverChange = useCallback(
    e => {
      const name = e.type === "mouseenter" ? "onMouseEnter" : "onMouseLeave";
      setHover(!hover);
      if (child.props[name]) {
        child.props[name](e);
      }
    },
    [setHover, hover, child]
  );

  return React.cloneElement(child, {
    onMouseEnter: onHoverChange,
    onMouseLeave: onHoverChange,
    style: Object.assign({}, child.props.style, hover ? hoverStyle : {}),
    ...props
  });
}
