import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import scrollToId from '../../helpers/scrollToId';
import Hoverable from "../Hoverable";


export default function NavMobile({ content, style, hoverColour, grey, activeSection }: { content: string[], style: React.CSSProperties, hoverColour: string, grey: string, activeSection: string }) {

  const [showList, setShowList] = useState(false);
  const clickListener = useCallback(_clickListener, [showList]);
  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener);
    }
  }, [clickListener]);

  function _clickListener(e: any) {
    if (!e.target.closest("mobile-list") && showList) {
      setShowList(false);
    }
  }

  function renderList() {
    return <div id="mobile-list" className={`mobile-list-view ${showList ? 'show' : ''}`} style={{ background: grey }}>
      {content.map(id =>
        <Hoverable
          key={id}
          hoverStyle={{ color: hoverColour }}
          onClick={(e: any) => {
            scrollToId(id); setShowList(false);
            e.stopPropagation();
          }}>

          <div className="item nav-selector" style={activeSection === id ? { color: hoverColour } : {}} >{id}</div>
        </Hoverable>
      )}
    </div>
  }

  return <nav
    onClick={e => console.log(e)}
    className="nav-bar fixed"
    style={style}
  >
    {renderList()}
    <FontAwesomeIcon
      size="xs"
      className={`item list-toggle ${!showList ? '' : 'rotate-90'}`}
      icon={faBars}
      onClick={(e: any) => { setShowList(prevShow => !prevShow); e.stopPropagation(); }}
    />
  </nav>
}