import React, { useState } from 'react';

import ThemeContext from './ThemeContext';

export default function ThemeProvider({ children }: any) {

  const [styles, setStyles] = useState({
    $primary: "#252934",
    $primaryDark: "#252f3f",
    $secondary: "#0da2a2",
    // $secondary: "#057f7f",
    $secondaryDark: "#005253",
    $accent: "#de4661",
    $grey: "#333",
  });

  function setColour(property: string, value: string) {
    setStyles(prevStyles => {
      return Object.assign({}, prevStyles, { [property]: value });
    })
  }

  return <ThemeContext.Provider value={{ styles, setColour }}>
    {children}
  </ThemeContext.Provider>
}
