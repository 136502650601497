import React, { useContext } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Footer.scss';
import Hoverable from './Hoverable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeContext from '../Contexts/ThemeContext';

export default React.memo(() => {
  const { styles } = useContext(ThemeContext);
  const { $primary, $secondary } = styles;
  return <footer style={{ background: $primary }}>
    <div className="icon-wrapper" >
      <Hoverable hoverStyle={{ color: $secondary }}>
        <FontAwesomeIcon size="sm" className="social-media-icon delay-1" onClick={() => window.open("https://medium.com/@jordanpawlett")} icon={['fab', 'medium-m']} />
      </Hoverable>
      <Hoverable hoverStyle={{ color: $secondary }}>
        <FontAwesomeIcon size="sm" className="social-media-icon" onClick={() => window.open("https://github.com/JordanPawlett")} icon={['fab', 'github']} />
      </Hoverable>
      <Hoverable hoverStyle={{ color: $secondary }}>
        <FontAwesomeIcon size="sm" className="social-media-icon delay-2" onClick={() => window.open("https://www.linkedin.com/in/jordanpawlett/")} icon={['fab', 'linkedin-in']} />
      </Hoverable>
    </div>
    <div className="name">GUD CODE LTD</div>
  </footer>;
});
