import React, { useContext, useRef, useState, useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedinIn, faGithub, faInstagram, faMediumM, faNode, faJs, faReact, faAngular, faJava, faGit, faDocker, faAws } from '@fortawesome/free-brands-svg-icons';
import './App.scss';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Nav from './Components/Nav/Nav';
import ThemeContext from './Contexts/ThemeContext';
import About from './Components/About';

library.add(faLinkedinIn, faGithub, faInstagram, faMediumM, faNode, faJs, faReact, faAngular, faJava, faGit, faDocker, faAws)

const App: React.FC = () => {
  const content = useRef([
    "home",
    "about"
  ])
  const { styles } = useContext(ThemeContext);
  const { $primary } = styles;
  const [activeSection, setActiveSection] = useState<string>(null as any);

  useEffect(() => {
    const elements = content.current.map(id => document.getElementById(id));
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const id = entry.target.getAttribute('id');
          setActiveSection(id as string);
        }
      });
    }, { threshold: 0.45 });
    elements.forEach(el => observer.observe(el as any));

    return () => {
      elements.forEach(el => observer.unobserve(el as any));
      observer.disconnect();
    }
  }, []);


  return (
    <div className="App" style={{ background: $primary }}>
      <Header id="home" />
      <Nav content={content.current} activeSection={activeSection} />
      <About isInView={activeSection === 'about'} id="about"/>
      <Footer />
    </div >
  );
}

export default App;
