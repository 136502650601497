import React, { useState, useEffect, useCallback, useContext } from 'react';

import ThemeContext from '../../Contexts/ThemeContext';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
import './Nav.scss';

export default function Nav({ activeSection, content }: { activeSection: string, content: string[] }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const calculateMobile = useCallback(_calculateMobile, [setIsMobile]);

  const { styles } = useContext(ThemeContext);
  const { $primaryDark, $secondary, $grey, $accent } = styles;

  useEffect(() => {
    window.addEventListener('resize', calculateMobile, false)
    return () => {
      window.removeEventListener('resize', calculateMobile);
    }
  }, [calculateMobile]);


  function _calculateMobile() {
    setIsMobile(window.innerWidth < 600)
  }

  return !isMobile ?
    <NavDesktop content={content} style={{ background: $primaryDark, borderBottom: `3px solid ${$secondary}` }} hoverColour={$accent} activeSection={activeSection} /> :
    <NavMobile content={content} style={{ background: $primaryDark, borderBottom: `3px solid ${$secondary}` }} hoverColour={$accent} grey={$grey} activeSection={activeSection} />
}