import React, { useState, useCallback, useEffect } from "react";
import scrollToId from '../../helpers/scrollToId';
import Hoverable from "../Hoverable";

export default function NavDesktop({ content, style, hoverColour, activeSection }: { content: string[], style: React.CSSProperties, hoverColour: string, activeSection: string }) {

  const [isFixed, setIsFixed] = useState(window.innerWidth < 600)
  const calculateFixed = useCallback(_calculateFixed, [setIsFixed]);

  useEffect(() => {
    window.addEventListener('scroll', calculateFixed, false)
    return () => {
      window.removeEventListener('scroll', calculateFixed);
    }
  }, [calculateFixed])

  function _calculateFixed() {
    const rect = document.getElementsByClassName("nav-bar")[0].getBoundingClientRect();
    setIsFixed(prevIsFixed => {
      if (!prevIsFixed) {
        return rect.y < -50;
      } else {
        return document.documentElement.scrollTop > (window.innerHeight);
      }
    });
  }

  function renderContent(normal: boolean) {
    return <nav
      className={`nav-bar ${!normal && !isFixed ? 'hide' : ''} ${!normal && isFixed ? 'fixed' : ''}`}
      style={style}
    >
      {content.map(id =>
        <Hoverable key={id} hoverStyle={{ color: hoverColour }} onClick={() => scrollToId(id)}>
          <div className="item nav-selector" style={activeSection === id ? { color: hoverColour } : {}}>{id}</div>
        </Hoverable>
      )}
    </nav>
  }

  return <>
    {renderContent(true)}
    {renderContent(false)}
  </>
}