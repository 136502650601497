import React from 'react';

export default React.createContext<{
  styles: {
    $primary: string,
    $primaryDark: string,
    $secondary: string,
    $secondaryDark: string,
    $accent: string,
    $grey: string,
  },
  setColour: (prop: string, value: string) => void
}>({
  styles: {
    $primary: "#252934",
    $primaryDark: "#252f3f",
    $secondary: "#0da2a2",
    // $secondary: "#057f7f",
    $secondaryDark: "#005253",
    $accent: "#de4661",
    $grey: "#333",
  },
  setColour: (p, v) => { }
});
